import { TOKEN_CONTRACT, STAKING_CONTRACT } from "../constants";

export async function fetchATKTokenBalance(web3: any, account: string) {
  let contract_stake = new web3.eth.Contract(
    TOKEN_CONTRACT.abi,
    TOKEN_CONTRACT.address
  );
  let owners_balance;
  try {
    owners_balance = await contract_stake.methods.balanceOf(account).call();
    return owners_balance;
  } catch (err) {
    console.log(err);
  }
}
export async function fetchPayoutAmount(web3: any) {
  let contract_stake = new web3.eth.Contract(
    TOKEN_CONTRACT.abi,
    TOKEN_CONTRACT.address
  );
  let owners_balance;
  try {
    owners_balance = await contract_stake.methods
      .balanceOf(STAKING_CONTRACT.address)
      .call();
    return owners_balance;
  } catch (err) {
    console.log(err);
  }
}
export async function getDailyProfit(web3: any, totalPrice: string) {
  let contract_stake = new web3.eth.Contract(
    STAKING_CONTRACT.abi,
    STAKING_CONTRACT.address
  );
  let owners_balance;
  try {
    owners_balance = await contract_stake.methods
      .getDailyProfit(totalPrice)
      .call();
    return owners_balance;
  } catch (err) {
    console.log(err);
  }
}

export async function approve(web3: any, address: string, totalPrice: string) {
  try {
    var amount = web3.utils.toBN(totalPrice);
    const contract_stake = new web3.eth.Contract(
      TOKEN_CONTRACT.abi,
      TOKEN_CONTRACT.address
    );
    await contract_stake.methods
      .approve(STAKING_CONTRACT.address, totalPrice)
      .send({ from: address, gasPrice: "500000000000" });
    return true;
  } catch (error) {
    console.log("errorr", error);
    return false;
  }
}

export async function callStake(
  address: string,
  web3: any,
  totalPrice: string
) {
  var amount = web3.utils.toBN(totalPrice);
  try {
    const contract_stake = new web3.eth.Contract(
      STAKING_CONTRACT.abi,
      STAKING_CONTRACT.address
    );
    await contract_stake.methods
      .stake(totalPrice)
      .send({ from: address, gasPrice: "500000000000" });
    return true;
  } catch (error) {
    console.log("errorr", error);
    return false;
  }
}

export async function fetchStakedTokenBalance(web3: any, address: string) {
  let contract_stake = new web3.eth.Contract(
    STAKING_CONTRACT.abi,
    STAKING_CONTRACT.address
  );
  let stakedBalance;
  try {
    stakedBalance = await contract_stake.methods
      .getStackingAmount(address)
      .call();
    return stakedBalance;
  } catch (err) {
    console.log(err);
  }
}
export async function fetchTotalStakedAmount(web3: any) {
  let contract_stake = new web3.eth.Contract(
    STAKING_CONTRACT.abi,
    STAKING_CONTRACT.address
  );
  let stakedBalance;
  try {
    stakedBalance = await contract_stake.methods.getTotalStaked().call();
    return stakedBalance;
  } catch (err) {
    console.log(err);
  }
}
export async function fetchStakingFee(web3: any) {
  let contract_stake = new web3.eth.Contract(
    STAKING_CONTRACT.abi,
    STAKING_CONTRACT.address
  );
  let stakedBalance;
  try {
    stakedBalance = await contract_stake.methods.getStakingFee().call();
    return stakedBalance;
  } catch (err) {
    console.log(err);
  }
}
export async function fetchApyRate(web3: any) {
  let contract_stake = new web3.eth.Contract(
    STAKING_CONTRACT.abi,
    STAKING_CONTRACT.address
  );
  let stakedBalance;
  try {
    stakedBalance = await contract_stake.methods.getApyRate().call();
    return stakedBalance;
  } catch (err) {
    console.log(err);
  }
}
export async function fetchWithDrawFee(web3: any) {
  let contract_stake = new web3.eth.Contract(
    STAKING_CONTRACT.abi,
    STAKING_CONTRACT.address
  );
  let stakedBalance;
  try {
    stakedBalance = await contract_stake.methods.getWithdrawFee().call();
    return stakedBalance;
  } catch (err) {
    console.log(err);
  }
}
export async function fetchMaxStakingAmount(web3: any) {
  let contract_stake = new web3.eth.Contract(
    STAKING_CONTRACT.abi,
    STAKING_CONTRACT.address
  );
  let stakedBalance;
  try {
    stakedBalance = await contract_stake.methods.getMaxmumAmount().call();
    return stakedBalance;
  } catch (err) {
    console.log(err);
  }
}

export async function callWithDraw(
  address: string,
  web3: any,
  withdrawAmount: string
) {
  var amount = web3.utils.toBN(withdrawAmount);
  try {
    const contract_stake = new web3.eth.Contract(
      STAKING_CONTRACT.abi,
      STAKING_CONTRACT.address
    );
    await contract_stake.methods
      .withdraw(withdrawAmount)
      .send({ from: address, gasPrice: "500000000000" });
    return true;
  } catch (error) {
    console.log("errorr", error);
    return false;
  }
}

export async function fetchEarnedTokenBalance(web3: any, address: string) {
  let contract_stake = new web3.eth.Contract(
    STAKING_CONTRACT.abi,
    STAKING_CONTRACT.address
  );
  let earnedBalance;
  try {
    earnedBalance = await contract_stake.methods
      .getEarnedAmount(address)
      .call();
    return earnedBalance;
  } catch (err) {
    console.log(err);
  }
}

export async function callClaim(address: string, web3: any) {
  try {
    const contract_stake = new web3.eth.Contract(
      STAKING_CONTRACT.abi,
      STAKING_CONTRACT.address
    );
    await contract_stake.methods
      .claimReward()
      .send({ from: address, gasPrice: "500000000000" });
    return true;
  } catch (error) {
    console.log("errorr", error);
    return false;
  }
}
export async function callRestake(address: string, web3: any) {
  try {
    const contract_stake = new web3.eth.Contract(
      STAKING_CONTRACT.abi,
      STAKING_CONTRACT.address
    );
    await contract_stake.methods
      .restake()
      .send({ from: address, gasPrice: "500000000000" });
    return true;
  } catch (error) {
    console.log("errorr", error);
    return false;
  }
}
