export const chainId = 137;

export const polygonNetworkInfo = {
  chainId: "80001",
  rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
  chainName: "Mumbai Testnet",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
};
