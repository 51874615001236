import * as React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import Web3 from "web3";
import Web3Modal from "web3modal";
// @ts-ignore
import WalletConnectProvider from "@walletconnect/web3-provider";
// @ts-ignore
import { getChainData } from "./helpers/utilities";
import Header from "./components/Home/Header";
import ScreenWrapper from "./components/Home/ScreenWrapper";
import Container from "@material-ui/core/Container";

import StakingScreen from "./pages/StakingScreen";

import {
  fetchATKTokenBalance,
  callStake,
  approve,
  fetchStakedTokenBalance,
  callWithDraw,
  fetchEarnedTokenBalance,
  callClaim,
  callRestake,
  fetchTotalStakedAmount,
  fetchMaxStakingAmount,
  fetchPayoutAmount,
  fetchStakingFee,
  fetchWithDrawFee,
  fetchApyRate,
} from "./helpers/web3";

import { chainId as networkChainId, polygonNetworkInfo } from "./attack_config";

interface IAppState {
  fetching: boolean;
  address: string;
  web3: any;
  provider: any;
  connected: boolean;
  chainId: number;
  networkId: number;
  showModal: boolean;
  withdrawLoading: boolean;
  stakeLoading: boolean;
  claimLoading: boolean;
  reStakeLoading: boolean;
  pendingRequest: boolean;
  result: any | null;
  // my state
  isHide: boolean;

  connectFlag: boolean;
  balance: number;
  atkTokenBalance: number;
  dailyProfitBalance: number;
  stakedTokenBalance: number;
  earnedTokenBalance: number;
  totalStakedAmount: number;
  maximumStakeAmount: number;
  payoutAmount: number;
  stakingFee: number;
  withDrawFee: number;
  apyRate: number;
}

const INITIAL_STATE: IAppState = {
  fetching: false,
  address: "",
  web3: null,
  provider: null,
  connected: false,
  chainId: 1,
  networkId: 1,
  showModal: false,
  withdrawLoading: false,
  stakeLoading: false,
  claimLoading: false,
  reStakeLoading: false,
  pendingRequest: false,
  result: null,
  isHide: true,
  connectFlag: false,
  balance: 0,
  atkTokenBalance: 0,
  dailyProfitBalance: 0,
  stakedTokenBalance: 0,
  earnedTokenBalance: 0,
  totalStakedAmount: 0,
  maximumStakeAmount: 0,
  payoutAmount: 0,
  stakingFee: 0,
  withDrawFee: 0,
  apyRate: 0,
};

function initWeb3(provider: any) {
  const web3: any = new Web3(provider);

  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber,
      },
    ],
  });
  return web3;
}

const history = createBrowserHistory();

class App extends React.Component<any, any> {
  // @ts-ignore
  public web3Modal: Web3Modal;
  public state: IAppState;

  constructor(props: any) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };

    this.web3Modal = new Web3Modal({
      network: this.getNetwork(),
      cacheProvider: true,
      providerOptions: this.getProviderOptions(),
      theme: {
        background: "rgba(43, 51, 94, 0.9)",
        main: "rgb(250, 250, 250)",
        secondary: "rgba(250, 250, 250, 0.7)",
        border: "rgba(196, 196, 196, 0.3)",
        hover: "rgba(53, 61, 104, 0.75)",
      },
    });
  }

  public componentDidMount() {
    if (this.web3Modal.cachedProvider) {
      this.onConnect();
    }
    this.setState({ isHide: true });
  }

  public onConnect = async () => {
    this.setState({ isHide: true });
    const provider = await this.web3Modal.connect();
    await this.subscribeProvider(provider);
    const web3: any = initWeb3(provider);
    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    const networkId = await web3.eth.net.getId();
    const chainId = await web3.eth.chainId();
    const walletBallance = await web3.eth.getBalance(address);
    const d: number = web3.utils.fromWei(walletBallance.toString(), "ether");

    await this.setState({
      web3,
      provider,
      connected: true,
      address,
      chainId,
      networkId,
    });

    if (chainId === networkChainId) {
      this.setState({ connectFlag: true });
      this.setState({ balance: parseFloat(d.toString()).toFixed(4) });
      await this.getTokensValue();
    } else {
      window.alert("Choose Polygon Network in your Wallet.");
      this.setState({ connectFlag: false });
    }
  };

  public subscribeProvider = async (provider: any) => {
    if (!provider.on) {
      return;
    }
    provider.on("close", () => this.resetApp());
    provider.on("accountsChanged", async (accounts: string[]) => {
      await this.setState({ address: accounts[0] });
    });
    provider.on("chainChanged", async (chainId: number) => {
      const { web3 } = this.state;
      const networkId = await web3.eth.net.getId();
      await this.setState({ chainId, networkId });
    });

    provider.on("networkChanged", async (networkId: number) => {
      const { web3 } = this.state;
      const chainId = await web3.eth.chainId();
      await this.setState({ chainId, networkId });
    });
  };

  public getNetwork = () => getChainData(this.state.chainId).network;

  public getProviderOptions = () => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          rpc: {
            137: "https://matic-mainnet.chainstacklabs.com",
          },
          infuraId: process.env.REACT_APP_INFURA_ID,
        },
      },
    };
    return providerOptions;
  };

  public toggleModal = () =>
    this.setState({ showModal: !this.state.showModal });

  public resetApp = async () => {
    const { web3 } = this.state;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
    await this.web3Modal.clearCachedProvider();
    this.setState({ ...INITIAL_STATE });
  };

  public _onHideMenu = (bool: boolean) => {
    this.setState({ isHide: bool });
  };

  public onCallStake = async (stakePrice: any) => {
    if (stakePrice === 0 || isNaN(stakePrice)) {
      window.alert("Please select stake amount.");
    } else if (stakePrice > this.state.atkTokenBalance) {
      window.alert("You don't have the amount you selected.");
    } else {
      this.setState({ stakeLoading: true });
      let contractCall = callStake;

      if (!contractCall) {
        throw new Error(
          `No matching contract calls for functionSig= callGetAuctions`
        );
      }

      const { web3, address } = this.state;
      let convertedPrice = web3.utils.toWei(stakePrice.toString(), "ether");
      let approveFlag = await approve(web3, address, convertedPrice);
      if (approveFlag) {
        try {
          let res = await contractCall(address, web3, convertedPrice);
          if (res) {
            this.setState({ stakeLoading: false });
            await this.getTokensValue();
          } else {
            this.setState({ stakeLoading: false });
          }
        } catch (error) {
          this.setState({ stakeLoading: false });
          this.setState({ web3, result: null });
        }
      } else this.setState({ stakeLoading: false });
    }
  };

  public onCallWithDraw = async (withDrawAmount: any) => {
    if (withDrawAmount === 0) {
      window.alert("Please select widthdraw amount.");
    } else if (withDrawAmount > this.state.stakedTokenBalance) {
      window.alert("You don't have the amount you selected.");
    } else {
      this.setState({ withdrawLoading: true });

      let contractCall = callWithDraw;

      if (!contractCall) {
        throw new Error(
          `No matching contract calls for functionSig= callGetAuctions`
        );
      }

      const { web3, address } = this.state;
      let convertedPrice = web3.utils.toWei(withDrawAmount.toString(), "ether");
      try {
        let res = await contractCall(address, web3, convertedPrice);
        if (res) {
          await this.getTokensValue();
          this.setState({ withdrawLoading: false });
        } else {
          this.setState({ withdrawLoading: false });
        }
      } catch (error) {
        this.setState({ withdrawLoading: false });
        this.setState({ web3, result: null });
      }
    }
  };
  public onCallClaim = async () => {
    if (this.state.earnedTokenBalance == 0) {
      window.alert("You don't have the earned token yet.");
    } else {
      this.setState({ claimLoading: true });
      let contractCall = callClaim;

      if (!contractCall) {
        throw new Error(
          `No matching contract calls for functionSig= callGetAuctions`
        );
      }

      const { web3, address } = this.state;
      try {
        let res = await contractCall(address, web3);
        if (res) {
          await this.getTokensValue();
          this.setState({ claimLoading: false });
        } else {
          this.setState({ claimLoading: false });
        }
      } catch (error) {
        this.setState({ claimLoading: false });
        this.setState({ web3, result: null });
      }
    }
  };
  public onCallRestake = async () => {
    if (this.state.earnedTokenBalance == 0) {
      window.alert("You don't have the earned token yet.");
    } else {
      this.setState({ reStakeLoading: true });
      let contractCall = callRestake;

      if (!contractCall) {
        throw new Error(
          `No matching contract calls for functionSig= callGetAuctions`
        );
      }

      const { web3, address } = this.state;
      try {
        let res = await contractCall(address, web3);
        if (res) {
          await this.getTokensValue();
          this.setState({ reStakeLoading: false });
        } else {
          this.setState({ reStakeLoading: false });
        }
      } catch (error) {
        this.setState({ reStakeLoading: false });
        this.setState({ web3, result: null });
      }
    }
  };

  public getTokensValue = async () => {
    const { web3, address } = this.state;
    await fetchEarnedTokenBalance(web3, address).then((balance) =>{
      if(balance !== undefined)
        this.setState({earnedTokenBalance: web3.utils.fromWei(balance.toString(), "ether")})
    });
    await fetchTotalStakedAmount(web3).then((balance) =>{
      if(balance !== undefined)
        this.setState({totalStakedAmount: web3.utils.fromWei(balance.toString(), "ether")})
    });
    await fetchMaxStakingAmount(web3).then((balance) =>{
      if(balance !== undefined)
        this.setState({maximumStakeAmount: web3.utils.fromWei(balance.toString(), "ether")})
    });
    await fetchStakingFee(web3).then((fee) =>{
      if(fee !== undefined)
        this.setState({stakingFee: fee})
    });
    await fetchWithDrawFee(web3).then((fee) =>{
      if(fee !== undefined)
        this.setState({withDrawFee: fee})
    });
    await fetchPayoutAmount(web3).then((balance) =>{
      if(balance !== undefined)
        this.setState({payoutAmount: web3.utils.fromWei(balance.toString(), "ether")})
    });
    await fetchApyRate(web3).then((rate) =>{
      if(rate !== undefined)
        this.setState({apyRate: rate})
    });
    await fetchStakedTokenBalance(web3, address).then((balance) =>{
      if(balance !== undefined)
        this.setState({stakedTokenBalance: web3.utils.fromWei(balance.toString(), "ether")})
    });
    await fetchATKTokenBalance(web3, address).then((balance) => {
      if(balance !== undefined)
        this.setState({atkTokenBalance: web3.utils.fromWei(balance.toString(), "ether")})
    });
    // let dailyProfitBalance = await getDailyProfit(web3, stakedTokenBalance);
    // let dailyProfitBalance = (stakedTokenBalance * apyRate) / 36500;
    //this.setState({
      //atkTokenBalance: web3.utils.fromWei(ATKTokenBalance.toString(), "ether"),
      //stakedTokenBalance: web3.utils.fromWei(stakedTokenBalance.toString(), "ether"),
      //earnedTokenBalance: web3.utils.fromWei(earnedTokenBalance.toString(), "ether"),
      // dailyProfitBalance: web3.utils.fromWei(
      //   dailyProfitBalance,
      //   "ether"
      // ),
      //totalStakedAmount: web3.utils.fromWei(totalStakedAmount.toString(), "ether"),
      //maximumStakeAmount: web3.utils.fromWei(maximumStakeAmount.toString(), "ether"),
      //stakingFee,
      //withDrawFee,
      //apyRate,
      //payoutAmount: web3.utils.fromWei(payoutAmount.toString(), "ether"),
    //});
  };

  public render = () => {
    const {
      address,
      connected,
      chainId,
      isHide,
      atkTokenBalance,
      stakedTokenBalance,
      earnedTokenBalance,
      stakeLoading,
      withdrawLoading,
      claimLoading,
      // dailyProfitBalance,
      reStakeLoading,
      maximumStakeAmount,
      totalStakedAmount,
      payoutAmount,
      stakingFee,
      apyRate,
      withDrawFee,
    } = this.state;
    return (
      <ScreenWrapper>
        <Router history={history}>
          <Container maxWidth="lg">
            <Header
              connect={this.onConnect}
              isHide={isHide}
              setIsHide={(e) => this._onHideMenu(e)}
              connected={connected}
              address={address}
              chainId={chainId}
              killSession={this.resetApp}
            />
          </Container>
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <StakingScreen
                  atkTokenBalance={atkTokenBalance}
                  stakedTokenBalance={stakedTokenBalance}
                  earnedTokenBalance={earnedTokenBalance}
                  // dailyProfitBalance={dailyProfitBalance}
                  payoutAmount={payoutAmount}
                  stakingFee={stakingFee}
                  withDrawFee={withDrawFee}
                  apyRate={apyRate}
                  stakeLoading={stakeLoading}
                  totalStakedAmount={totalStakedAmount}
                  maximumStakeAmount={maximumStakeAmount}
                  reStakeLoading={reStakeLoading}
                  withdrawLoading={withdrawLoading}
                  claimLoading={claimLoading}
                  onCallStake={this.onCallStake}
                  onCallWithDraw={this.onCallWithDraw}
                  onCallClaim={this.onCallClaim}
                  onCallRestake={this.onCallRestake}
                />
              )}
            />
          </Switch>
        </Router>
      </ScreenWrapper>
    );
  };
}

export default App;
