import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";

import landingBgI from "../../assets/images/landingBgI.png";
import logo1 from "../../assets/images/logo1.png";

interface Props {
  atkTokenBalance: number;
  stakedTokenBalance: number;
  earnedTokenBalance: number;
  // dailyProfitBalance: number;
  maximumStakeAmount: number;
  totalStakedAmount: number;
  apyRate: number;
  payoutAmount: number;
  stakeLoading: boolean;
  withdrawLoading: boolean;
  withDrawFee: number;
  stakingFee: number;
  claimLoading: boolean;
  reStakeLoading: boolean;
  onCallStake: (stakePrice: any) => void;
  onCallWithDraw: (stakePrice: any) => void;
  onCallClaim: () => void;
  onCallRestake: () => void;
}

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 650px) {
    margin-top: 0px;
  }
`;
const StackInfoArea = styled.div`
  width: 1240px;
  margin: 0 auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  @media (max-width: 1300px) {
    width: 95%;
  }
  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;
const ApproveButton = styled.div`
  color: white;
  font-size: 20px;
  font-family: Oswald-Regular;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ed2185;
  border-radius: 4px;
  margin-top: 30px;
  &:hover {
    cursor: pointer;
  }
`;
const ApproveButton1 = styled.div`
  color: white;
  font-size: 20px;
  font-family: Oswald-Regular;
  width: 100%;
  height: 60px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-top: 30px;
  border: 1.5px solid #ed2185;
  &:hover {
    cursor: pointer;
  }
`;
const ApproveButton2 = styled.div`
  color: white;
  font-size: 20px;
  font-family: Oswald-Regular;
  width: 100%;
  height: 60px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-top: 30px;
  border: 1.5px solid #ed2185;
  &:hover {
    cursor: not-allowed;
  }
`;
const StakingTitle1 = styled.p`
  font-size: 16px;
  color: #929393;
  font-family: Oswald-Light;
  margin: 5px 0;
  text-transform: uppercase;
`;
const TopBannerArea = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding-top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const TopBannerTitle = styled.p`
  font-size: 84px;
  position: absolute;
  text-align: center;
  color: white;
  font-family: "Oswald-Medium";
  text-transform: uppercase;
  margin: 2em auto;
  margin-top: 8%;
  @media screen and (max-width: 790px) {
    font-size: 40px;
  }
`;
const LeftArea = styled.div`
  width: 37.5%;
  background-image: url(/images/apyBackground.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 30px;
  border-radius: 8px;
  padding: 3em 2em;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1080px) {
    flex-direction: row;
    width: 100%;
    align-items: center;
  }
  @media screen and (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const RightArea = styled.div`
  width: 62.5%;
  height: 100%;
  padding-left: 2.5%;
  margin-top: 30px;
  @media screen and (max-width: 1080px) {
    width: 100%;
    padding-left: 0%;
  }
`;
const RightTopArea = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`;
const RightTopAreaItem = styled.div`
  width: 48.5%;
  background-color: #252728;
  border-radius: 12px;
  padding: 1.7em;
  & h6 {
    font-family: "Oswald-Regular";
    font-size: 20px;
    color: #a8a9a9;
    margin: 10px 0;
    text-transform: uppercase;
  }
  & button {
    color: #ed2185;
    background-color: transparent;
    font-size: 16px;
    font-family: Oswald-Regular;
    text-decoration: underline;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    margin-top: 15px;
    & h6 {
      font-size: 16px;
    }
    & button {
      font-size: 14px;
    }
  }
`;
const AmountInputArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid #3b3d3e;
  justify-content: space-between;
  & input {
    outline: none;
    border-width: 0;
    width: 100%;
    margin-right: 10px;
    background-color: transparent !important;
    color: white;
    font-size: 52px !important;
    font-family: Oswald-SemiBold !important;
  }
  & input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  & p {
    font-size: 40px;
    font-family: Oswald-ExtraLight;
    color: white;
    margin: 0;
    margin-bottom: 5px;
  }
`;
const LeftTopArea = styled.div`
  height: 286px;
  border-left: 4px solid #ed2185;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2em;
  @media screen and (max-width: 650px) {
    height: 120px;
  }
`;
const Title1 = styled.p`
  font-size: 74px;
  color: white;
  font-family: "Oswald-Light";
  margin: 0;
  line-height: 1.1;
  @media screen and (max-width: 650px) {
    font-size: 40px;
  }
`;
const Title2 = styled.p`
  font-size: 124px;
  color: white;
  font-family: "Oswald-Bold";
  margin: 0;
  line-height: 1.1;
  @media screen and (max-width: 1038px) {
    font-size: 65px;
  }
`;
const LeftBottomArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  @media screen and (max-width: 1080px) {
    margin-top: 0px;
    align-items: flex-end;
  }
  @media screen and (max-width: 650px) {
    padding-left: 0%;
    margin-top: 0px;
    align-items: center;
  }
`;
const RightBottomArea = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0;
  background-color: #191717;
  max-width: 350px;
  width: 100%;
  height: 98px;
  border-radius: 4px;
  padding-left: 25px;
  text-transform: uppercase;
  @media screen and (max-width: 650px) {
  }
  & p {
    color: #a3a2a2;
    font-family: Oswald-Regular;
    font-size: 16px;
    margin: 0;
  }
  &:hover {
    cursor: pointer;
  }
`;
const CategoryText = styled.h1`
  font-size: 30px;
  color: white;
  font-family: "Oswald-SemiBold";
  margin: 0;
  @media screen and (max-width: 650px) {
    font-size: 24px;
  }
`;
const LegacyLink = styled.a`
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  font-family: "Oswald-Bold";
  font-size: 20px;
  align-self: flex-end;
  margin-bottom: 50px;
  margin-top: 0;

  &:hover {
    color: #ed2185;
  }
`;

interface Props {}

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

const StakingContainer = (props: Props) => {
  const classes = useStyles();
  const [walletTokenVal, setWalletTokenVal] = useState(0);
  const [depositeTokenVal, setDepositeTokenVal] = useState(0);
  const [earnAmount, setEarnAmount] = useState(0);
  const [stakingVal, setStakingVal] = useState(0);
  const [withdrawVal, setWithdrawVal] = useState(0);
  const [loading, setLoading] = useState(0);
  const [maximumStakeAmount, setMaximumStakeAmount] = useState(0);
  const [totalStakedAmount, setTotalStakedAmount] = useState(0);
  const [dailyProfitBalance, setDailyProfitBalance] = useState(0);

  useEffect(() => {
    let dailyProfitBalance = (props.stakedTokenBalance * props.apyRate) / 36500;
    setDailyProfitBalance(dailyProfitBalance);
    setWalletTokenVal(props.atkTokenBalance);
    setDepositeTokenVal(props.stakedTokenBalance);
    setEarnAmount(props.earnedTokenBalance);
    setTotalStakedAmount(props.totalStakedAmount);
    setMaximumStakeAmount(props.maximumStakeAmount);
    if (
      !props.stakeLoading ||
      !props.withdrawLoading ||
      !props.claimLoading ||
      !props.reStakeLoading
    ) {
      setStakingVal(0);
      setWithdrawVal(0);
    }
  }, [props]);

  return (
    <MainContainer>
      <TopBannerArea>
        <img src={landingBgI} alt="" style={{ width: "100%" }} />
        <TopBannerTitle>ATK Staking</TopBannerTitle>
      </TopBannerArea>
      <StackInfoArea>
        <LeftArea>
          <LeftTopArea>
            <Title1>APY</Title1>
            <Title2>{props.apyRate}%</Title2>
          </LeftTopArea>
          <LeftBottomArea>
            <RightBottomArea>
              <p>Total Staked Amount</p>
              <CategoryText>
                {parseFloat(totalStakedAmount.toString()).toFixed(4)}{" "}
                <span style={{ fontFamily: "Oswald-ExtraLight" }}>ATK</span>
              </CategoryText>
            </RightBottomArea>
            <RightBottomArea>
              <p>Max Staking amount</p>
              <CategoryText>
                {parseFloat(maximumStakeAmount.toString()).toFixed(4)}{" "}
                <span style={{ fontFamily: "Oswald-ExtraLight" }}>ATK</span>
              </CategoryText>
            </RightBottomArea>
            <RightBottomArea>
              <p>Payout treasury</p>
              <CategoryText>
                {parseFloat(
                  (props.payoutAmount - totalStakedAmount).toString()
                ).toFixed(4)}{" "}
                <span style={{ fontFamily: "Oswald-ExtraLight" }}>ATK</span>
              </CategoryText>
            </RightBottomArea>
          </LeftBottomArea>
        </LeftArea>
        <RightArea>
          <RightTopArea>
            <RightTopAreaItem>
              <StakingTitle1 style={{ margin: 0, textAlign: "start" }}>
                In Your Wallet <br />
                <span style={{ color: " white", fontFamily: "Oswald-Regular" }}>
                  {" "}
                  {parseFloat(walletTokenVal.toString()).toFixed(4)} ATK
                </span>
              </StakingTitle1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <h6>Amount to Stake</h6>
                <button onClick={() => setStakingVal(walletTokenVal)}>
                  Use 100%
                </button>
              </div>
              <AmountInputArea>
                <input
                  value={stakingVal || ""}
                  onChange={(e) => {
                    setStakingVal(parseFloat(e.target.value));
                  }}
                  type="number"
                  placeholder="0"
                />
                <p>ATK</p>
              </AmountInputArea>
              <ApproveButton
                onClick={() => {
                  props.onCallStake(stakingVal);
                }}
              >
                Stake
                {props.stakeLoading && <CircularProgress size={20} />}
              </ApproveButton>
              <p
                style={{
                  color: "#adaeae",
                  fontFamily: "Oswald-Light",
                  fontSize: 14,
                  marginTop: 20,
                  letterSpacing: 1.1,
                  marginBottom: 0,
                }}
              >
                *{props.stakingFee}% staking fee will be applied
              </p>
            </RightTopAreaItem>
            <RightTopAreaItem>
              <StakingTitle1 style={{ margin: 0, textAlign: "start" }}>
                Your Stake <br />
                <span style={{ color: " white", fontFamily: "Oswald-Regular" }}>
                  {" "}
                  {parseFloat(depositeTokenVal.toString()).toFixed(4)} ATK
                </span>
              </StakingTitle1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <h6>Amount to Withdraw</h6>
                <button
                  onClick={() => {
                    setWithdrawVal(depositeTokenVal);
                  }}
                >
                  Use 100%
                </button>
              </div>
              <AmountInputArea>
                <input
                  value={withdrawVal || ""}
                  onChange={(e) => setWithdrawVal(parseFloat(e.target.value))}
                  type="number"
                  placeholder="0"
                />
                <p>ATK</p>
              </AmountInputArea>
              {depositeTokenVal == 0 || withdrawVal == NaN ? (
                <ApproveButton2>Withdraw</ApproveButton2>
              ) : (
                <ApproveButton1
                  style={{ display: "flex", flexDirection: "row" }}
                  onClick={() => props.onCallWithDraw(withdrawVal)}
                >
                  Withdraw
                  {props.withdrawLoading && <CircularProgress size={20} />}
                </ApproveButton1>
              )}
              <p
                style={{
                  color: "#adaeae",
                  fontFamily: "Oswald-Light",
                  fontSize: 14,
                  marginTop: 20,
                  letterSpacing: 1.1,
                  marginBottom: 0,
                }}
              >
                *{props.withDrawFee}% withdrawal fee will be applied
              </p>
            </RightTopAreaItem>
          </RightTopArea>
          <RightTopArea>
            <RightTopAreaItem style={{ width: "100%", marginTop: 20 }}>
              <StakingTitle1 style={{ margin: 0, textAlign: "start" }}>
                Earnings Per Day <br />
                <span style={{ color: " white", fontFamily: "Oswald-Regular" }}>
                  {" "}
                  {parseFloat(dailyProfitBalance.toString()).toFixed(4)} ATK
                </span>
              </StakingTitle1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <h6>Unclaimed Balance</h6>
              </div>
              <AmountInputArea>
                <input
                  disabled={true}
                  value={parseFloat(earnAmount.toString()).toFixed(4)}
                  type="number"
                  placeholder="0"
                />
                <p>ATK</p>
              </AmountInputArea>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {earnAmount == 0 ? (
                  <ApproveButton2 style={{ marginRight: 15 }}>
                    Claim
                  </ApproveButton2>
                ) : (
                  <ApproveButton1
                    style={{ marginRight: 15 }}
                    onClick={() => props.onCallClaim()}
                  >
                    Claim
                    {props.claimLoading && <CircularProgress size={20} />}
                  </ApproveButton1>
                )}
                {earnAmount == 0 ? (
                  <ApproveButton2>Restake</ApproveButton2>
                ) : (
                  <ApproveButton1
                    style={{ marginLeft: 0 }}
                    onClick={() => props.onCallRestake()}
                  >
                    Restake
                    {props.reStakeLoading && <CircularProgress size={20} />}
                  </ApproveButton1>
                )}
              </div>
              <p
                style={{
                  color: "#adaeae",
                  fontFamily: "Oswald-Light",
                  fontSize: 14,
                  marginTop: 20,
                  letterSpacing: 1.1,
                  marginBottom: 0,
                }}
              >
                *No fees for claiming or restaking
              </p>
            </RightTopAreaItem>
          </RightTopArea>
        </RightArea>
      </StackInfoArea>
      <StackInfoArea style={{ justifyContent: "flex-end" }}>
        <LegacyLink
          href="https://attackwagon.com/staking.html#LegacyStaking"
          target="_blank"
        >
          View Legacy Staking
        </LegacyLink>
      </StackInfoArea>
    </MainContainer>
  );
};

export default StakingContainer;
