import React, { useState, useEffect } from "react";
import styled from "styled-components";
import LogoArea from "./LogoArea";
import MenuArea from "./MenuArea";
import SocialLinkArea from "./SocialLinkArea";
import ToggleMenuIcon from "./ToggleMenuIcon";
import DisconnectBtn from "../DisconnectBtn";

import mailIcon from "../../assets/images/mailIcon.png";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
interface Props {
  connect: () => void;
  setIsHide: (e: any) => void;
  isHide: boolean;
  connected: boolean;
  address: string;
  chainId: number;
  killSession: () => void;
}

const ConnectButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ed2185;
  width: 177px;
  height: 48px;
  border-radius: 5px;
  font-size: 16px;
  font-family: Oswald-Regular;
  justify-content: center;
  color: white;
  & img {
    width: 26px;
    height: 26px;
    margin-right: 10px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const Header: React.FC<Props> = ({
  setIsHide,
  isHide,
  connect,
  killSession,
  connected,
  address,
  chainId,
}) => {
  const [currentWidth, setCurrentWidth] = useState(0);
  useEffect(() => {
    function updateSize() {
      setCurrentWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  useEffect(() => {
    setIsHide(true);
  }, [currentWidth]);
  return (
    <Wrapper>
      <LogoArea />
      {!connected && (
        <ConnectButton
          onClick={() => {
            connect();
          }}
        >
          <img src={mailIcon} alt="" />
          Connect Wallet
        </ConnectButton>
      )}
      {connected && (
        <DisconnectBtn
          killSession={killSession}
          connected={connected}
          address={address}
          chainId={chainId}
        />
      )}
    </Wrapper>
  );
};

export default Header;
