import React from "react";
import Container from "@material-ui/core/Container";

import StakingContainer from "../components/Home/StakingContainer";

interface Props {
  atkTokenBalance: number;
  stakedTokenBalance: number;
  earnedTokenBalance: number;
  // dailyProfitBalance: number;
  maximumStakeAmount: number;
  totalStakedAmount: number;
  stakingFee: number;
  withDrawFee: number;
  apyRate: number;
  payoutAmount: number;
  stakeLoading: boolean;
  withdrawLoading: boolean;
  claimLoading: boolean;
  reStakeLoading: boolean;
  onCallStake: (stakePrice: any) => void;
  onCallWithDraw: (stakePrice: any) => void;
  onCallClaim: () => void;
  onCallRestake: () => void;
}

export default function StakingScreen(props: Props) {
  return (
    <StakingContainer
      atkTokenBalance={props.atkTokenBalance}
      stakedTokenBalance={props.stakedTokenBalance}
      earnedTokenBalance={props.earnedTokenBalance}
      // dailyProfitBalance={props.dailyProfitBalance}
      stakeLoading={props.stakeLoading}
      withdrawLoading={props.withdrawLoading}
      claimLoading={props.claimLoading}
      reStakeLoading={props.reStakeLoading}
      totalStakedAmount={props.totalStakedAmount}
      maximumStakeAmount={props.maximumStakeAmount}
      payoutAmount={props.payoutAmount}
      onCallStake={props.onCallStake}
      onCallWithDraw={props.onCallWithDraw}
      onCallClaim={props.onCallClaim}
      onCallRestake={props.onCallRestake}
      withDrawFee={props.withDrawFee}
      stakingFee={props.stakingFee}
      apyRate={props.apyRate}
    />
  );
}
